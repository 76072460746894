<template>
  <div class="mainDiv">
    <div class="content">
      <div class="head">Thank you!</div>
      <div class="body text-center">
        Please wait a minute while we process your form, don't refresh or close the page in the meantime.
        <br>
        <br>
        Once activated, please tap the “Next” button at the bottom of the page to complete your application.
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>